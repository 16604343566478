import { Link } from "gatsby";
import { graphql } from "gatsby";
import { kebabCase } from "lodash";
import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    allMarkdownRemark: {
      group: Array<{
        fieldValue: string;
        totalCount: number;
      }>;
    };
  };
}

const TagsPage: React.SFC<Props> = ({ data: { allMarkdownRemark: { group }, site: { siteMetadata: { title } } } }) => {
  const tags = group.map(tag => (
    <div className="control" key={tag.fieldValue}>
      <div className="tags has-addons">
        <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
          <span className="tag is-large is-warning">{tag.fieldValue}</span>
        <span className="tag is-large">{tag.totalCount}</span>
        </Link>
      </div>
    </div>
  ));

  return <Layout>
    <Helmet title={`Tags - ${title}`} />
    <div className="hero is-dark is-bold">
      <div className="hero-body">
        <section className="container is-narrow">
          <h1 className="title">Tags</h1>
        </section>
      </div>
    </div>
    <div className="container is-narrow">
      <section className="section">
        <div className="field is-grouped is-grouped-multiline">{tags}</div>
      </section>
    </div>
  </Layout>;
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
